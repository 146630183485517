.sign-in-page {
  .new-account,
  .return-user {
    .error_messages {
      width: 390px;
      margin: 15px auto;
    }
  }
}

.address-book-page {
  &__overlay {
    .address {
      &-form {
        .form-item {
          float: left;
          width: 50%;
          padding-right: 1.33em;
          padding-bottom: 0;
          &.postal-code,
          &.city,
          &.state {
            width: 50%;
          }
          &.address1 {
            width: 100%;
          }
        }
        &__item {
          margin-bottom: 0;
          &.help_container {
            .lookup_submit_note {
              display: inline-block;
              margin: 0.5em 0 1.5em;
            }
          }
        }
      }
    }
    .buttons {
      text-align: left;
      display: inline-block;
      width: 100%;
      .button {
        &--secondary {
          margin-right: 20px;
        }
      }
    }
    input[type='text'],
    select {
      width: 100%;
    }
  }
}

.profile-page {
  &__content {
    .optional-info {
      .form-item {
        width: auto;
        display: inline-block;
        margin-right: 15px;
      }
    }
    p {
      margin: 0;
    }
    .newsletter-info {
      &__fieldset {
        width: 50%;
        float: left;
        margin-right: 1.6%;
        margin-bottom: 0;
        padding: 1.5em 0;
      }
    }
    .sms-promotions {
      width: 48.4%;
      float: left;
      padding: 1.5em 0;
    }
  }
}

// Replenishment
.replenishment {
  &-page {
    &__header {
      text-align: left;
    }
    &__overlay {
      .replenish-cancel-confirm {
        &__link {
          text-decoration: none;
        }
      }
    }
    &__content {
      .replenishment {
        &__products {
          .products-header {
            border-bottom: 1px solid $color-lightest-grey;
            margin-bottom: 1em;
            height: 30px;
            &__product {
              width: 60%;
            }
            &__price {
              width: 17.5%;
              float: right;
            }
            &__next-order {
              width: 20%;
            }
          }
        }
      }
    }
  }
  &-page,
  &-detail-page {
    &__content {
      .product {
        padding-bottom: 25px;
        &__img {
          width: 12.5%;
          padding-right: 1em;
        }
        &__info {
          width: 30%;
          float: left;
          padding-right: 1em;
          padding-left: 0;
        }
        &__price {
          float: left;
          width: 38%;
          text-align: center;
        }
        &__next-order {
          width: 12%;
          float: left;
        }
        &__frequency {
          @include breakpoint($medium-portrait-only) {
            padding-left: 15%;
            clear: both;
            width: 80%;
            margin-top: 18px;
          }
          .link {
            border: 0;
          }
        }
      }
    }
  }
  &-detail-page {
    overflow: auto;
    .product {
      &__price {
        width: 12.5%;
        text-align: center;
        float: right;
      }
      &__next-order-date {
        display: none;
      }
      &__info {
        width: 50%;
      }
    }
    &__content {
      margin-bottom: 20px;
    }
  }
}

.payment_form {
  .field-container {
    .payment-form {
      &__item {
        &:nth-of-type(2n + 1) {
          width: 50%;
          float: left;
          padding-right: 1em;
          clear: both;
        }
        &:nth-of-type(2n + 2) {
          width: 50%;
          float: right;
          margin-left: 0;
          margin-right: 0;
          clear: none;
          padding-right: 0;
        }
        select,
        a,
        input {
          width: 100%;
        }
        .label-content {
          display: none;
        }
        .expiry_year {
          margin-top: 22px;
        }
      }
    }
  }
  fieldset {
    max-width: 67%;
  }
}

.payment-delete {
  &__info {
    margin-bottom: 5px;
  }
  &__link {
    @include btn-style();
    width: 40%;
    &:hover,
    &:focus {
      @include btn-style-hover();
    }
    &:nth-of-type(2n + 1) {
      float: left;
      margin-right: 20px;
    }
    &:nth-of-type(2n) {
      float: left;
      background: $color-white;
      color: $color-dark-gray;
      border-color: $color-lighter-gray;
      &:hover,
      &:focus {
        @include btn-style-hover();
      }
    }
  }
}
