/* Home page signin overlay */
.site-header {
  &__utility {
    .gnav-util {
      &__content {
        &--account {
          overflow: auto;
          height: auto;
        }
        &--signup {
          .site-email-signup {
            padding: 20px 0;
          }
        }
      }
    }
  }
  &__trustmark {
    &-wrapper {
      float: left;
      position: relative;
      top: -18px;
    }
  }
  &__menu-wrapper {
    clear: both;
  }
  &__menu {
    .menu__item {
      &--lvl-1__title {
        margin: 0 10px;
      }
    }
  }
  &__account-menu-select-selectBox-dropdown-menu {
    min-width: 190px;
  }
}

.sticky-add-to-bag_live-chat {
  @include breakpoint($sticky-breakpoint) {
    position: relative;
    float: left;
    margin-top: 3px;
    margin-right: 20px;
    font-size: 11px;
  }
}

.sticky-add-to-baglive-chat_disc {
  @include breakpoint($sticky-breakpoint) {
    position: absolute;
    top: 10px;
    left: 20px;
    color: $color-salmon;
    font-size: 45px;
    height: 13px;
    line-height: 13px;
  }
}

.payment-form {
  input[type='tel'] {
    &.is-filled-in {
      &:required {
        &:invalid {
          border-color: $color-lighter-gray;
        }
      }
    }
  }
}

.site-footer-pc {
  .site-footer {
    &__country-chooser-wrapper {
      a.selectBox-dropdown,
      .selectBox {
        position: absolute;
      }
    }
  }
}

.viewcart {
  .checkout {
    &__sidebar {
      .samples-panel {
        .product-list {
          min-height: auto !important;
        }
      }
    }
  }
}

.section-home {
  .product-brief {
    &__header {
      min-height: 100%;
      @include breakpoint($medium-portrait-only) {
        min-height: 275px;
      }
    }
  }
}

.cart-block {
  &__footer {
    &__button {
      &--delivery-container {
        .link {
          font-size: 11px;
        }
      }
    }
  }
}

.customer-service {
  &__subtitle {
    font-size: 20px;
  }
  .privacy-policy {
    p {
      font-size: 13px;
    }
  }
}

.cs-page {
  &__content-item {
    .cs-contact-form {
      &__section {
        p {
          width: 98%;
        }
      }
    }
  }
}

.favorites {
  &__list {
    .product {
      &__detail {
        min-height: 290px;
      }
    }
    .coming_soon_stock_msg {
      min-height: 153px;
    }
  }
}

.waitlist-form {
  margin: initial;
}

#footer_sticky_chat {
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 9998;
}

.sticky-add-to-bag {
  &__live-chat {
    @include breakpoint($sticky-breakpoint) {
      margin-bottom: 90px;
    }
  }
}

.zdc {
  &_popup {
    .zdc__dollar {
      &--buttons {
        .button {
          padding: 10px;
          width: auto;
        }
      }
    }
  }
}

.adv-cal-cap-wrapper {
  .card-content {
    .offer-img-bkg {
      &.hover {
        backface-visibility: visible !important;
        background-color: $advent-calendar-tile-color;
      }
    }
  }
}
