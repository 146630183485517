.viewcart {
  .checkout {
    &__content {
      .viewcart {
        &-header {
          border-bottom: 1px solid $color-light-gray;
          padding-bottom: 30px;
          h2 {
            display: block;
            padding: 0;
            text-transform: uppercase;
            font-size: 32px;
            line-height: 36px;
            text-align: left;
            width: 90%;
            float: left;
          }
          .item-count {
            display: inline-block;
            width: 10%;
          }
        }
      }
      .bottom-viewcart-buttons {
        .continue-shopping {
          float: left;
        }
      }
      .panel {
        header {
          border-top: 1px solid $color-light-gray;
        }
      }
    }
    &__sidebar {
      header {
        padding: 0;
      }
      #favorites-panel,
      #past-purchases-panel,
      .samples-panel {
        .product-list {
          overflow: auto;
          .product {
            height: auto;
            &-img {
              float: left;
              width: 32%;
              .view-larger {
                display: none;
              }
            }
          }
          .details {
            margin-left: 0;
            float: right;
            width: 65%;
            .btn {
              width: 140px;
              margin: 5px 0;
            }
          }
        }
        .dot-pager {
          display: none;
        }
      }
      #samples-panel {
        footer {
          display: none;
        }
      }
    }
  }
  .panel {
    header {
      padding: 20px 0 4px;
      position: relative;
      margin: 0.5em 0;
      width: 100%;
      font-weight: bold;
    }
  }
}

.continue-buttons {
  margin: 20px 0;
  text-align: left;
  .text_link {
    font-weight: normal;
    margin: 10px 0 0;
    letter-spacing: 1px;
    display: inline-block;
    float: left;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .continue-checkout {
    &-btns {
      float: right;
      margin: 10px 0 0;
    }
  }
}

.viewcart-buttons-panel {
  .continue-buttons {
    display: none;
  }
  &.viewcart-buttons-empty {
    .continue-buttons {
      display: block;
    }
  }
}

.promo-panel {
  .banner {
    margin-bottom: 10px;
  }
}

.cart-items {
  .cart {
    &-header {
      display: block;
      .products {
        @include cart-items();
        width: 45%;
        padding-left: 1.5em;
      }
      .price {
        @include cart-items();
        width: 18%;
        text-align: center;
        padding-left: 0;
      }
      .qty {
        @include cart-items();
        width: 13%;
        text-align: center;
        padding-left: 0;
      }
      .total {
        @include cart-items();
        width: 19%;
        float: right;
        text-align: center;
        padding-left: 0;
      }
    }
    &-item {
      border-top: 1px solid $color-lightest-grey;
      padding: 35px 0;
      position: relative;
      margin: 0;
      box-sizing: border-box;
      &__thumb {
        padding: 0;
        float: left;
        width: 15%;
        margin-right: 1%;
      }
      &__desc {
        width: 32%;
        margin-bottom: 0;
        padding-right: 1%;
        position: relative;
        float: left;
        .product_name,
        .sub_line {
          a {
            text-decoration: none;
          }
        }
      }
      &__remove-form {
        background-size: auto 100%;
      }
      &__price {
        text-align: center;
        float: left;
        width: 18%;
        &-label {
          display: none;
        }
      }
      &__qty {
        margin: 0;
        float: left;
        width: 13%;
        text-align: center;
        &-label {
          display: none;
        }
        .selectBox-dropdown,
        .selectBox {
          min-width: 70px;
        }
      }
      &__total {
        width: 19%;
        text-align: center;
        float: right;
        font-weight: bold;
        padding-right: 2em;
      }
      .replenishment {
        padding-left: 16%;
        width: 100%;
        float: left;
        &__description {
          margin-bottom: 10px;
          .overlay-link {
            border-bottom: 1px solid $color-dark-gray;
            text-decoration: none;
          }
        }
        .replen_notice {
          margin-top: 5px;
        }
      }
    }
  }
}

.order-summary-panel {
  .label {
    text-transform: uppercase;
    width: 85%;
    float: left;
    margin-bottom: 1.5em;
  }
  .value {
    width: 15%;
    float: right;
    font-weight: normal;
    text-align: right;
    padding-right: 2.8em;
  }
  .total {
    border-top: 1px solid $color-lightest-grey;
    font-weight: bold;
    margin-bottom: 0;
    padding: 1em 2.8em 1em 0;
  }
  .shipping {
    display: none;
  }
}

.delivery_options {
  &__content {
    select {
      width: 100%;
    }
  }
}

.checkout {
  .shipping-panel {
    .form {
      &-item,
      &_element {
        margin: 0 10px 0 0;
        display: inline-block;
        width: 48%;
        margin-bottom: 20px;
        input[type='text'] {
          width: 100%;
        }
        &.postal_code_container {
          width: 100%;
          position: relative;
          input {
            width: 48%;
          }
          .fieldset-note {
            top: 0;
            left: 409px;
            position: absolute;
          }
          label {
            display: block;
          }
          .lookup_submit_note {
            display: inline-flex;
            margin-top: 10px;
          }
        }
        &.state_container {
          select {
            width: 100%;
          }
        }
      }
    }
    .fs {
      &.address {
        max-width: 100%;
      }
    }
    .content {
      &.column {
        float: left;
        width: 45%;
        padding: 0 10px 20px 10px;
      }
    }
    .gift-options {
      &__content {
        .form-item {
          width: 100%;
        }
      }
    }
  }
  .payment-panel {
    clear: both;
    .form-item {
      &.block {
        &.card-number {
          position: static;
        }
      }
    }
  }
  &__sidebar {
    .panel {
      &.need-help-panel {
        .email {
          @include btn-style();
          font-size: 12px;
          min-width: 140px;
          width: 100%;
          &:hover,
          &:focus {
            @include btn-style-hover();
          }
        }
      }
      .viewcart-header {
        margin-bottom: 10px;
      }
      .viewcart-panel {
        &__title {
          float: left;
        }
      }
      .item-count {
        display: inline-block;
        padding: 3% 0 0 25%;
        @include breakpoint($medium-landscape-only) {
          padding: 3% 0 0 8%;
        }
        @include breakpoint($medium-portrait-only) {
          padding: 0 0 0 60%;
        }
      }
      #shopping-bag-edit {
        margin-left: 10px;
      }
    }
    .remaining {
      .samples-panel {
        &__promo-text {
          display: none;
          &.large {
            display: inline-block;
          }
        }
        &__heading {
          display: none;
        }
      }
    }
  }
  #checkout_delivery {
    p {
      margin: 15px 0 5px;
    }
  }
  &.viewcart {
    @include breakpoint($medium-portrait-only) {
      overflow: hidden;
    }
  }
}
/* Samples page */
.samples {
  position: relative;
  &-overlay {
    #samples-panel {
      .product-list {
        overflow: hidden;
        > li {
          width: 50%;
          height: 240px;
          position: relative;
          .product-img {
            width: 85px;
            float: left;
            max-width: 108px;
            text-align: center;
            .view-larger {
              display: none;
            }
          }
          .details {
            margin-left: 120px;
            clear: none;
            margin-right: 0;
          }
          .sample-select-button {
            width: 60%;
          }
        }
      }
      .samples-buttons {
        &.top {
          display: none;
        }
        &.bottom {
          display: block;
          text-align: center;
          .add-button {
            padding: 20px;
          }
          .continue-with-no-sample-btn {
            display: none;
          }
        }
      }
      header {
        border-bottom: 1px solid $color-light-gray;
        margin: 0;
        padding-top: 0;
      }
      &__title {
        margin-bottom: 0;
      }
      p {
        &.available {
          margin-top: 1em;
        }
      }
      ul {
        &.skus {
          margin-top: 20px;
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
      .remaining {
        .samples-panel {
          &__promo-text {
            display: none;
            &.large {
              display: inline-block;
              margin-top: 10px;
            }
          }
        }
      }
    }
    .samples {
      position: relative;
    }
  }
}
/* Order review & payment */
#index {
  .checkout {
    .finished {
      &.sign-in-panel {
        border-top: 1px solid $color-lightest-grey;
      }
      &.shipping-panel {
        & > header {
          padding: 20px 10px 10px 10px;
          border-bottom: 1px solid $color-lightest-grey;
          margin-bottom: 10px;
          .checkout {
            &__panel-title {
              display: inline-block;
            }
          }
          & > a {
            margin: 0 0 0 15px;
          }
        }
        .gift-options-display {
          > a {
            &.edit {
              background: 0 0;
              color: $color-black;
              padding: 0;
              border: 0;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .edit {
      &.shipping-panel {
        & > header {
          padding: 10px 10px 0;
          border-bottom: 1px solid $color-lightest-grey;
        }
        .sub-section {
          .edit-shipping-radio,
          .edit-billing-radio {
            margin: 20px 0;
            span {
              display: inline-block;
              width: 45%;
              label {
                display: inline;
              }
            }
          }
          .edit-billing-radio {
            label {
              display: block;
            }
          }
          &.new-address {
            .edit-shipping-radio {
              margin-top: 0;
            }
            .shipping-address-display {
              margin: 0 0 20px 0;
            }
          }
          .billing-address-form {
            margin-bottom: 40px;
          }
        }
        .continue-button-wrapper {
          float: right;
          margin: 0px 25px 0 0;
        }
        .gift-options {
          &__content {
            fieldset {
              &.fs {
                max-width: 97%;
              }
            }
          }
        }
        .email-and-sms {
          &-promotions {
            .pc_email_promo_container {
              width: 100%;
            }
          }
        }
      }
    }
    #registration {
      &-panel {
        header {
          clear: both;
        }
        .registration {
          &-form-container {
            .form-item {
              &.password {
                .label-class {
                  display: block;
                  margin: 10px 0 5px 0;
                }
              }
              input[type='password'] {
                width: 40%;
                margin: 0 0 10px 0;
              }
            }
            .accepted-privacy-policy {
              margin: 0 0 30px 0;
            }
            .continue-button-wrapper {
              margin-right: 50px;
              text-align: right;
            }
          }
        }
      }
    }
    .payment-panel {
      .field-are-required {
        position: inherit;
      }
      .payment-edit-content {
        .payment-container {
          float: left;
          width: 100%;
          input {
            float: left;
            margin-right: 5px;
          }
          label {
            width: 45%;
            float: left;
          }
          &.save-card {
            margin: 0;
          }
          &__option {
            &--rakuten ~ label {
              width: 100%;
              margin-top: 10px;
            }
          }
        }
        .continue-button-wrapper {
          &.rakuten {
            input {
              width: 295px;
              background: no-repeat center/100% url('/media/export/cms/checkout/rpay_btn.png');
              height: 80px;
              border: none;
              color: transparent;
              outline: 0;
            }
          }
          float: right;
          margin: 10px 25px 0 0;
        }
        input[type='text'],
        input[type='tel'],
        selectBox,
        select {
          width: 100%;
        }
        .payment_gmo {
          .expiry {
            width: 68%;
            float: left;
            .expires-month-wrapper,
            .expires-year-wrapper {
              display: inline-block;
              width: 48%;
              margin: 20px 0;
            }
          }
          .cvv {
            display: inline-block;
            width: 32%;
            margin: 42px 0 20px 0;
          }
          span {
            margin-right: 31%;
          }
          .payment-form {
            margin-top: 10px;
          }
        }
      }
    }
    .offer-code-panel {
      input[type='text'] {
        width: 56%;
        float: left;
        @include breakpoint($tablet-only) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      input[type='submit'] {
        width: 35%;
        float: right;
        @include breakpoint($tablet-only) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .offer-code {
        &__one-offer-only {
          display: inline-block;
          margin-top: 10px;
        }
      }
      #offer_code {
        float: left;
        @include breakpoint($medium-portrait-only) {
          float: none;
        }
      }
    }
  }
}

html {
  &.js {
    body {
      &#viewcart {
        #recommended-products {
          &-panel {
            display: none;
            header {
              margin-bottom: 0;
              padding-bottom: 0;
            }
            .recommended-item {
              margin-bottom: 30px;
              .description {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
                .product {
                  &_name,
                  &_subline {
                    a {
                      float: none;
                    }
                  }
                }
              }
              .addtobag {
                text-align: center;
              }
            }
          }
        }
        .offer-code-panel {
          input[type='text'] {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.orders-list {
  &__item {
    .order-details {
      &-page {
        &__content {
          .order-products {
            .cart-item {
              &__total {
                padding-left: 0;
                width: 16%;
                &.cart-item {
                  &__header {
                    float: left;
                  }
                }
              }
              &__qty {
                width: 30%;
              }
              &__replenishment {
                width: 20%;
              }
              &__taxed {
                width: auto;
              }
              &__price {
                width: 14%;
              }
              &.product {
                .cart-item {
                  &__price {
                    width: 14%;
                  }
                }
              }
              .cart-item {
                &__product-subname,
                &__product-sub_line {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
.birth-month-container {
  .select-birth-month {
    width: 25%;
  }
  .checkout-birthday {
    &__descriptive-text {
      padding-top: 10px;
    }
    &__birth-month {
      margin-bottom: 10px;
    }
  }
}
.checkout-confirmation {
  &-page {
    .birthday-panel {
      padding-top: 10px;
    }
  }
}

.wp-samples-landing-panel,
.wp-samples-panel {
  .checkout__panel-title,
  .offer-samples {
    text-align: center;
  }
  .samples-buttons {
    &.bottom {
      margin: 0 15px 15px 10px;
      .add-cart {
        float: right;
      }
    }
  }
  .product-list {
    .product {
      &:nth-child(3n + 1) {
        clear: both;
      }
      .sku {
        left: 0;
        bottom: 0;
        right: 0;
        margin: 10px auto;
        position: absolute;
      }
    }
  }
  .details {
    position: relative;
    min-height: 150px;
  }
}

.wp-samples-panel {
  .return-link {
    display: none;
  }
}

.offerspick-action-buttons {
  margin: 0 20px 5px 20px;
  .edit-cart {
    width: 50%;
    float: left;
  }
  .continue-buttons {
    .continue-checkout {
      width: auto;
      height: auto;
      line-height: 10px;
      margin-bottom: 10px;
      font-size: 11px;
      float: right;
    }
  }
}

.active-panel-wp_samples {
  .viewcart-buttons-panel {
    .edit-cart,
    .continue-buttons {
      display: block;
    }
  }
}

#viewcart {
  .column {
    &.left {
      .engraving {
        .js-label-mode + label {
          width: 30%;
          text-align: center;
          line-height: 15px;
          margin-top: -55px;
          height: 18px;
          @include breakpoint($medium-up) {
            width: 15%;
          }
          &::before {
            content: none;
          }
        }
      }
    }
  }
}
