.appt-book {
  #counters {
    @include breakpoint($landscape-down) {
      padding-top: 120px;
    }
  }
  .ui-select {
    margin-right: 0;
  }
  .location-submit {
    width: 310px;
  }
  .service-select {
    .service {
      .service_tile-content {
        @include breakpoint($tablet-only) {
          float: right;
        }
      }
      .selection-bar {
        .selection-time {
          width: 40%;
        }
        .selection-selector {
          width: 60%;
        }
      }
    }
  }
  &.counters {
    .appt-book-page-header {
      max-width: none;
    }
    .appt-book-page-header-content {
      left: 0px;
      @include breakpoint($landscape-down) {
        max-width: 60%;
        left: 60px;
      }
    }
    .appt-book-location__signin-text {
      p {
        margin: 25% 10%;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        overflow: visible;
        .registration__email-list-text {
          font-family: 'Neue Haas Unica Pro';
          font-size: 12px;
          font-variant: normal;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 18px;
        }
        fieldset {
          &.mobile-phone-fieldset {
            .appt-book-mobile {
              width: 100%;
            }
          }
        }
        .registration__email-list {
          height: 80px;
          width: 48%;
          padding: 0 10px 0 0;
        }
        .registration__privacy-policy-list {
          width: 48%;
          height: 80px;
          float: left;
          margin-right: 10px;
          padding: 0 0 0 10px;
        }
        .appt-book-email {
          margin-right: 0;
        }
      }
    }
  }
}

.appt-book-overlay {
  &.appt-book-book-conflict-overlay,
  &.appt-book-services-overlay,
  &.appt-book-services-unavailabe-overlay {
    .overlay-content-container {
      height: auto;
      .overlay-content {
        height: auto;
      }
    }
  }
  .overlay-content-container {
    .overlay-content {
      height: auto;
    }
  }
}
