$font--neue-haas-unica-pro: apple-system, BlinkMacSystemFont, 'Neue Haas Unica Pro', '游ゴシック Medium', YuGothic,
  YuGothicM, 'Hiragino Kaku Gothic ProN', メイリオ, Meiryo, sans-serif;
$font--lamer-headline: 'La Mer Headline', 游明朝, 'YuGothic', YuGothicM, 'Hiragino Mincho ProN', HGS明朝E, メイリオ,
  Meiryo;
$font--lamer-text: 'La Mer Text', '游ゴシック Medium', YuGothic, YuGothicM, 'Hiragino Kaku Gothic ProN', メイリオ,
  Meiryo, sans-serif;
$font--text: $font--neue-haas-unica-pro;
$font--heading: $font--lamer-headline;
$font--subheading: $font--lamer-text;

@import 'overrides/overrides';

//Specific to Mobile Base Theme
@import '../../cremedelamer2_base/scss/cremedelamer2-base-pc';

//Specific to font
@import 'sections/_jp-fonts';

//Mixin to entire mobile & PC
@import 'sections/_mixins';

//Specific to entire Pc site
@import 'sections/all_pc';

//Common to entire Mobile & PC  site
@import 'sections/_all';

//Specific to account flow
@import 'sections/pc/account/_account';

//Specific to checkout flow
@import 'sections/pc/checkout/_checkout';
@import 'sections/bogo/bogo-offers';

// Specific to Appointment Booking feature
@import 'sections/appt_booking';

// market specific SCSS
@import 'sections/pc/account/account-reset-password-strength';
// One Trust Cookie Styles.
@import '../../cremedelamer2_base/scss/sections/one_trust_cookie/cookie_settings_ot';

// loyalty
@import 'sections/loyalty';
